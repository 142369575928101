@media screen and (min-width:1200px)
{

}
@media screen and (max-width:1200px)
{
    .footer1-dad-middle1-1
    {
        font-size: .9em;
    }
    .footer1-dad-middle1-3-ul li
    {
        font-size: .7em;
    }
    .footer1-dad-middle2-3-ul
    {
        font-size: .9em;
    }
    .footer1-dad-left-img img
    {
        width: 210px;
    }
    .footer1-dad-left-txt
    {
        font-size:14px ;
    }
    .main2-dashB-right-bottom-a
    {
        font-size: 1.2em;
    }   
} 
@media screen and (max-width:1176px)
{
    /* .best-table-actions-a
    {
        padding: 4 16.762px;
        font-size: .97em;
        margin:0 13.968px 13.968px 0;
    } */
    th , td {
        font-size: .8em;
    }
}  
@media screen and (max-width:1080px)
{
    .main1-FAQ-dad-middle1-txt1
    {
        font-size: 1.1em;
    }
    .main1-FAQ-dad-middle1-txt2a
    {
        font-size: .9em;
    }
    .main1-FAQ-dad-middle1-txt3
    {
        font-size: .9em;
    }

    .footer1-dad-middle1-1
    {
        font-size: .8em;
    }
    .footer1-dad-middle1-3-ul li
    {
        font-size: .6em;
    }
    .footer1-dad-middle2-3-ul
    {
        font-size: .8em;
    }
    .footer1-dad-left-img img
    {
        width: 180px;
    }
    .footer1-dad-left-txt
    {
        font-size:13px ;
    }
    .footer2
    {
        font-size: .8em;
    }
    .main2-dashB-right-bottom-a
    {
        font-size: 1.1em;
    } 
    th , td {
        font-size: .697em;
    }
}  
@media screen and (max-width:965px)
{
    .main1-FAQ-dad-middle1-txt1
    {
        font-size: 1em;
    }
    .main1-FAQ-dad-middle1-txt2a
    {
        font-size: .8em;
    }
    .main1-FAQ-dad-middle1-txt3
    {
        font-size: .8em;
    }
    .footer1-dad-middle1-1
    {
        font-size: .7em;
    }
    .footer1-dad-middle1-3-ul li
    {
        font-size: .5em;
    }
    .footer1-dad-middle2-3-ul
    {
        font-size: .7em;
    }
    .footer1-dad-left-img img
    {
        width: 160px;
    }
    .footer1-dad-left-txt
    {
        font-size:12px ;
    }
    .footer2
    {
        font-size: .7em;
    }
    .main2-dashB-right-bottom-a
    {
        font-size: .9em;
    } 
    .main2-kontoD-2
    {
        font-size: .89em;
    }
    th , td {
        font-size: .55em;
    }
} 
@media screen and (max-width:850px)
{
    .main1-FAQ-dad-middle1-txt2a
    {
        font-size: .7em;
    }
    .main1-FAQ-dad-middle1-txt3
    {
        font-size: .7em;
    }
    .main2-dashB-dad
    {
        display: flex;
        flex-direction: column;
    }
    .main2-dashB-left
    {
        width: 100%;
        padding: 0;
        border-right:none;
    }
    .main2-dashB-right
    {
        width: 100%;
        margin: 30px 0 50 0;
        padding-bottom: 30px;
    }
    .main2-dashB-right-bottom-ul li
    {
        width: 49%;
    }
    .main2-dashB-right-bottom-a
    {
        font-size: 1.3em;
    } 
    .main2-add-right
    {
        width: 100%;
        margin: 30px 0 50 0;
        padding-bottom: 30px;
    }
    .main2-add-right-txt2
    {
        flex-direction: column;
    }
    .footer1-dad-left
    {
        width: 95%;
    }
    .footer1-dad-middle1
    {
        width: 95%;
    }
    .footer1-dad-middle2
    {
        width: 95%;
    }
    .footer1-dad-middle1-1
    {
        font-size: 1em;
    }
    .footer1-dad-middle1-3-ul li
    {
        font-size: .8em;

    }
    .footer1-dad-middle2-3-ul
    {
        font-size: 1em;
    }
    .footer1-dad-left-img img
    {
        width: 512px;
    }
    .footer1-dad-left-txt
    {
        font-size:16px ;
        width:95%;
        margin-top: 70px;
    }
    .footer1-dad
    {
        flex-direction: column;
    }
    .footer2
    {
        font-size: .9em;
    }
    .footer2-dad
    {
        flex-direction: column;
        text-align: center;
    }
    .footer2-dad-right
    {
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }
    #icon-border
    {
        top: 50%;
    }
    #icon-border2
    {
        top: 69%;
    } 
    .main1-dashB
    {
        text-align: center;
    }
    .best-table-actions-a
    {
        padding: 8 16.762px;
        font-size: .97em;
        margin:0 13.968px 13.968px 0;
    }
    th , td {
        font-size: .8em;
    }
} 
@media screen and (max-width:760px)
{
    .main1-uber-dad-down
    {
        flex-direction: column;
    }
    .main1-uber-dad-down-left
    {
        width: 100%;
    }
    .main1-uber-dad-down-right
    {
        width: 100%;
        margin: 30px auto;
    }
    .main1-uber-dad-down-left {
        margin: 0 auto;
    }
}   
@media screen and (max-width:720px)
{
    .main1-FAQ-dad-middle
    {
        justify-content: center;
    }
    .main1-FAQ-dad-middle1
    {
        width: 240px;
        height: 180px; 
        margin: 12px;
    }
    th , td {
        font-size: .7em;
    }
    .menu-dad
    {
        width: 100%;
    }
}   
@media screen and (max-width:650px){
    .footer1-dad-left-img img
    {
        width: 440px;
    }
    .main1-FAQ-dad-middle1
    {
        width: 200px;
    }
    .main1-FAQ-dad-bottom1-1
    {
        font-size: 1em;
    }
    .main1-FAQ-dad-bottom2
    {
        font-size: 1em;
    }
    .main1-FAQ-dad-bottom1-2
    {
        font-size: .9em;
    }
    .main1-FAQ-dad-bottom1-3
    {
        font-size: .9em;
    }
    .main2-kart-middle input
    {
        width: 80%;
    }
    th , td {
        font-size: .6em;
    }
   
}
@media screen and (max-width:560px){
    .footer1-dad-left-img img
    {
        width: 380px;
    }
    .main1-FAQ-dad-middle1
    {
        width: 180px;
    }
    .main1-FAQ-dad-top
    {
        font-size: 1.6em;
    }
    .main1-FAQ-dad-bottom1-1
    {
        font-size: .85em;
    }
    .main1-FAQ-dad-bottom2
    {
        font-size: .89em;
    }
    .main1-FAQ-dad-bottom1-2
    {
        font-size: .8em;
    }
    .main1-FAQ-dad-bottom1-3
    {
        font-size: .8em;
    }
    .main2-add-right-txt2-box1-txt
    {
        font-size: 1em;
    }
    .main2-add-right1
    {
        flex-direction: column;
    }
    .main2-kontoD-1
    {
        font-size: .9em;
        width: 100%;
    }
    .main2-kontoD-2
    {
        font-size: .8em;
    }
}
@media screen and (max-width:557px){
    .best-table-actions-a
    {
        padding: 3 16.762px;
    }
}
@media screen and (max-width:490px){
    .footer1-dad-left-img img
    {
        width: 300px;
    }
    .footer1-dad-left-txt
    {
        font-size:14px ;
    }
    .main1-FAQ-dad-top
    {
        font-size: 1.5em;
    }
    .main1-FAQ-dad-bottom1-1
    {
        font-size: .75em;
    }
    .main1-FAQ-dad-bottom2
    {
        font-size: .75em;
    }
    .main1-FAQ-dad-bottom1-2
    {
        font-size: .7em;
    }
    .main1-FAQ-dad-bottom1-3
    {
        font-size: .7em;
    }
    .main1-kontakt-dad2-box
    {
        justify-content: center;
    }
    .main2-dashB-right-bottom-ul li
    {
        width: 99%;
    }
    .main2-kontoD-2
    {
        font-size: .7em;
    }
}
@media screen and (max-width:430px){
    .main1-FAQ-dad-bottom1-1
    {
        font-size: .7em;
    }
    .main1-FAQ-dad-bottom2
    {
        font-size: .7em;
    }
    .main2-kontoD-2
    {
        font-size: .6em;
    }
}
@media screen and (max-width:400px){
    .footer1-dad-left-img img
    {
        width: 250px;
    }
    .footer1-dad-left-txt
    {
        font-size:12px ;
    }
    .main1-FAQ-dad-top
    {
        font-size: 1.2em;
    }
    .main1-FAQ-dad-bottom1-1
    {
        font-size: .65em;
    }
    .main1-FAQ-dad-bottom2
    {
        font-size: .63em;
    }
    .main1-FAQ-dad-bottom1-2
    {
        font-size: .6em;
    }
    .main1-FAQ-dad-bottom1-3
    {
        font-size: .6em;
    }
    .main2-kontoD-1
    {
        font-size: .79em;
    }
}
@media screen and (max-width:388px){
    .main2-kart-middle1 i{
        display: none !important;
    }
    .main2-kart-a
    {
    font-size: .87em;
    }
}
