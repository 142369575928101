.footer1 {
  background-color: #f7f7f7;
  /* height:263.55px ; */
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.footer1-dad {
  margin-top: 31px;
  color: #777;
}
.footer1-dad-left {
  margin: 0;
  padding: 0 15px 30px;
  width: 25%;
}
.footer1-dad-left-img img {
  width: 282.5px;
}
.footer1-dad-left-txt {
  font-size: 15px;
  font-weight: 400;
  width: 282.5px;
  width: 95%;
  margin-top: 15px;
  /* text-align: justify; */
}
.footer1-dad-middle1 {
  margin: 0;
  padding: 0 15px 30px;
  width: 25%;
}
.footer1-dad-middle1-1 {
  font-size: 1em;
  font-weight: 600;
}
.footer1-dad-middle1-2 {
  height: 3px;
  display: block;
  /* background-color: rgba(0,0,0,.1); */
  margin: 1em 0 1em;
  width: 100%;
  max-width: 30px;
}
.footer1-dad-middle1-3-ul li {
  margin-bottom: 1.3em;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.footer1-dad-middle1-3-ul li:hover {
  margin-bottom: 1.3em;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: rgba(17, 17, 17, 0.85);
  transition: 2s;
  cursor: pointer;
}
.color-a {
  color: #777;
  /* line-style: none; */
}
.color-a:hover {
  color: rgba(17, 17, 17, 0.85);
}
.footer1-dad-middle1-3-ul li i {
  font-size: 16px;
}
#icon-border {
  position: absolute;
  top: 41%;
  width: 1px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 15px;
  left: 0;
}
#icon-border2 {
  position: absolute;
  top: 57%;
  width: 1px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 15px;
  left: 0;
}
.footer1-dad-middle2 {
  margin: 0;
  padding: 0 15px 30px;
  width: 25%;
}
.footer1-dad-middle2-3-ul {
  margin-bottom: 1.3em;
  font-size: 1em;
  font-weight: 400;
}
.footer1-dad-middle2-3-ul li {
  padding: 6px 0;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
  width: 100%;
  display: inline-block;
  cursor: pointer;
}
.footer1-dad-middle2-3-ul li:hover {
  padding: 6px 0;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
  width: 100%;
  color: #ee7f01;
  cursor: pointer;
}
.color-a2 {
  color: #777;
}
.color-a2:hover {
  color: #ee7f01;
}
#middle2-3 {
  border-bottom: none;
}
.footer2 {
  color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 15px;
  font-size: 0.9em;
  /* height: 73.4px; */
  /* background-color: rgb(236, 219, 195); */
  padding-bottom: 40px;
}
.footer2-dad {
  display: flex;
  justify-content: space-between;
}
.footer2-dad-right {
  width: 168px;
  height: 33.4px;
  padding: 7.5px 0;
}
.footer2-dad-right1 {
  width: 50px;
  height: 30px;
  margin: 3px;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.back-to-top {
  width: 34.8px;
  height: 34.8px;
  border: 2px solid #888888;
  border-radius: 100%;
  line-height: 2em;
  font-size: 0.97em;
  cursor: pointer;
  font-weight: bolder;
  position: fixed;
  right: 1.5em;
  bottom: 1.5em;
  text-align: center;
  color: #888888;
}
.back-to-top:hover {
  width: 34.8px;
  height: 34.8px;
  border: 2px solid #ee7f01;
  border-radius: 100%;
  line-height: 2em;
  font-size: 0.97em;
  cursor: pointer;
  font-weight: bolder;
  position: fixed;
  right: 1.5em;
  bottom: 1.5em;
  text-align: center;
  color: #fff;
  background-color: #ee7f01;
}
/* .back-to-top-i{
    width: .4em;
    height: .4em;
} */
