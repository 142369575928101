
.login-page{
    min-height: 100vh;
}
.left-items{
    color: white;
    background: linear-gradient(to right, rgb(255, 187, 0) 0%, orange 20% , rgb(255, 123, 0) 100%);
    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
}
.left-items--text-container{
    width: 90%;
    text-align: center;
}
.left-items--title span{
    font-size: 30px;
    font-weight: bold;
    margin-top: 45%;
    width: 85%;
}
.left-items--content{
    margin-top: 15px;
    font-size: 30px;
}
.right-items--container{
    width: 70%;
    text-align: center;
}
.login-account-icon i{
    margin-top: 220px;
    font-size: 55px;
    background-color: #B8B8B8;
    color: white;
    border-radius: 60%;
    width: 90px;
}
.login-account-rigester-icon i{
    margin-top:50px;
    font-size: 55px;
    background-color: #B8B8B8;
    color: white;
    border-radius: 60%;
    width: 90px;
}
.right-items--text{
    color: #B8B8B8;
    font-size: 15px;
    margin-top: 15px;
    font-weight: 600;
}
.login-input{
    background-color: #F0F0F0;
    width: 80%;
    height: 50px;
    margin-top: 20px;
    border-radius: 25px;
    text-align: left;
}
.input-icons{ 
    align-items: center;
    line-height: 50px;
    width: 12%;
}
.input-icons i{ 
    margin-left: 15px;
    color: #B8B8B8;
    font-size: 20px;
}
.input-text{
    width: 88%;
}
.input-text input{
    margin-top: 14px;
    border: none;
    background: none;
    width: 95%;
    outline: none;
    color: #928c8c !important;
    padding: 0px!important;
}
.input-text input::placeholder{
    color: #A0A0A0;
    font-size: 15px;
}
.login-btn{
    margin-top: 50px;
    background: linear-gradient(to right, rgb(255, 123, 0) 0% , orangered 70%);
    width: 200px;
    border-radius: 25px;
    height: 50px;
    cursor: pointer;
}
.login-btn button{
    border: none;
    background: none;
    color: white;
    line-height: 50px;
    font-size: 17px;
    cursor: pointer;
}
.register-text{
    margin-top: 15px;
    color: #C8C8C8;
    font-size: 15px;
    cursor: pointer;
}
.register-text span a{
    color: orange;
    margin-left: 4px;
}

.login-button-text{
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 10px
}
/* ////////////////////////////////////////responsive///////////////////////////// */
@media screen and (max-width:1025px){
    .left-items--title span{
        margin-top: 55%;
    }
    .login-account-icon i{
        margin-top: 120px;
    }
    .input-text input{
        margin-left: 7px;
    }
}
@media screen and (max-width:912px){
    .left-items{
        display: none;
    }
    .right-items{
        width: 100%;
    }
    .input-icons{
        width: auto;
    }
}

.input-text .rs-form-control-wrapper{
    width: 100% !important;
    height: 50px !important;
}

.input-text .rs-picker-toggle-wrapper{
    width: 100% !important;
    height: 50px !important;
}

.input-text .rs-picker-default .rs-picker-toggle.rs-btn{
    height: 50px !important; 
}

.input-text .rs-picker-default .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-btn, .rs-picker-input .rs-picker-toggle{
    background: #f0f0f0!important;
}