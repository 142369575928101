@font-face {
  font-family: "poppins";
  src: url(../font/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "poppins-b";
  src: url(../font/Poppins/Poppins-Bold.ttf);
}
ul {
  margin: 0px;
  padding: 0px;
}
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-family: "poppins", sans-serif;
  line-height: 1.6;
  color: #777;
}
html {
  width: 100%;
  height: 100%;
}
li {
  list-style: none;
}
table {
  width: 100%;
  margin-bottom: 1em;
  border-color: #ececec;
  border-spacing: 0;
}
th,
td {
  padding: 0.5em;
  text-align: left;
  border-bottom: 1px solid #ececec;
  line-height: 1.3;
  font-size: 0.9em;
}
th {
  line-height: 1.05;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-width: 3px;
  display: table-cell;
  vertical-align: inherit;
  font-family: "poppins-b";
  padding: 7.2px 7.2px 7.2px 0;
}
.flex {
  display: flex;
}
.flex-j-c {
  display: flex;
  justify-content: center;
}
.flex-j-s {
  display: flex;
  justify-content: space-between;
}
.d-r {
  direction: rtl;
}
.d-l {
  direction: ltr;
}
.bc {
  background-color: chocolate;
}
.bac {
  background-color: burlywood;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-94 {
  width: 94%;
}
.w-95 {
  width: 95%;
}
.w-96 {
  width: 96%;
}
.w-97 {
  width: 97%;
}
.w-92 {
  width: 92%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-77 {
  width: 77%;
}
.w-75 {
  width: 75%;
}
.w-73 {
  width: 73%;
}
.w-70 {
  width: 70%;
}
.w-69 {
  width: 69%;
}
.w-60 {
  width: 60%;
}
.w-55 {
  width: 55%;
}
.w-54 {
  width: 54%;
}
.w-50 {
  width: 50%;
}
.w-43 {
  width: 43%;
}
.w-48 {
  width: 48%;
}
.w-47 {
  width: 47%;
}
.w-46 {
  width: 46%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-25 {
  width: 25%;
}
.w-24 {
  width: 24%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
.position-r {
  position: relative;
}
.position-a {
  position: absolute;
}
.m-r {
  margin-right: 20px;
}
.t-r {
  text-align: right;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-24 {
  margin-top: 24px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-11 {
  margin-top: 11px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-43 {
  margin-top: 43px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-16 {
  margin-top: 16px;
}
.p-t-70 {
  padding-top: 70px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.m-t-18 {
  margin-top: 18px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.t-c {
  text-align: center;
}
.t-l {
  text-align: left;
}

.bold {
  font-weight: bold;
}
.w-99 {
  width: 99%;
}
.w-98 {
  width: 98%;
}
.w-62 {
  width: 62%;
}
.w-63 {
  width: 63%;
}
.w-18 {
  width: 18%;
}
.p-l {
  padding: 0px 15px;
}
.bacc {
  background-color: pink;
}
.m-0-a {
  margin: 0 auto;
}
a {
  text-decoration: none !important;
  color: #555555;
}

a:hover {
  text-decoration: none !important;
  color: #ee7f01;
}
.dashB-li {
  border-right: 3.5px solid #ee7f01;
}
.cursor-p {
  cursor: pointer;
}
.m-12
{
  margin:0 12px;
}
