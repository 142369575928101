.main1-FAQ {
  /* height: 906.5px; */
  /* background-color: rgb(253, 200, 227); */
}
.main1-FAQ-dad {
  background-color: #ffffff;
  margin: 30px;
}
.main1-FAQ-dad-top {
  font-weight: 700;
  font-size: 1.7em;
  line-height: 1.3;
  color: #555;
  width: 100%;
  font-family: "poppins-b";
}
.main1-FAQ-dad-top2 {
  height: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 16px auto;
  width: 100%;
  max-width: 30px;
}
.main1-FAQ-dad-middle {
  margin-top: 24px;
  /* background-color: rgb(98, 21, 199); */
  /* height: 193.2px; */
}
.main1-FAQ-dad-middle1 {
  background-color: #ffffff;
  min-width: 282.5px;
  min-height: 193.2px;
  width: 23%;
  text-align: center;
  padding: 0px;
  margin: 0;
  border: 1px solid rgba(230, 224, 224, 0.85);
  border-top: 0.5px solid rgba(230, 224, 224, 0.15);
  border-bottom: 2px solid rgba(212, 205, 205, 0.95);
}
.main1-FAQ-dad-middle1:hover {
  background-color: #ffffff;
  min-width: 282.5px;
  min-height: 193.2px;
  text-align: center;
  padding: 0px;
  margin: 0;
  /* border: 1px solid rgba(0,0,0,.06); */
  border: 1px solid rgba(230, 224, 224, 0.85);
  border-top: 0.5px solid rgba(230, 224, 224, 0.15);
  border-bottom: 2px solid rgba(212, 205, 205, 0.95);
  box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: 0.5s;
}
#main1-FAQ-dad-middle1 {
  margin: 0 12px 24px 12px;
}
#main1-FAQ-dad-middle1:hover {
  margin: 0 12px 24px 12px;
}
.main1-FAQ-dad-middle1-img img {
  width: 60px;
  height: 63.2px;
  margin-top: 3.2px;
}
.main1-FAQ-dad-middle1-txt1 {
  font-family: "poppins-b";
  color: rgb(0, 106, 208);
  margin-top: 16px;
  font-size: 1.25em;
}
.main1-FAQ-dad-middle1-txt2a {
  color: #555555;
  /* margin: 10px auto; */
  font-size: 1em;
}
.main1-FAQ-dad-middle1-txt2a:hover {
  color: #ee7f01;
  margin-top: 10px;
  font-size: 1em;
}
.main1-FAQ-dad-middle1-txt3 {
  color: #777;
  font-size: 1em;
  margin-bottom: 20.8px;
}
#main1-FAQ-dad-middle1-txt3 {
  margin-bottom: 0px;
}
.main1-FAQ-dad-bottom h3 {
  font-size: 1.25em;
  font-weight: 700;
  color: #555;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0.5em;
  text-rendering: optimizeSpeed;
  /* border-bottom: 1px solid rgba(0,0,0,.06); */
  /* padding-bottom: 10px; */
}

.main1-FAQ-dad-bottom1-1 {
  color: #ee7f01;
  font-family: "poppins-b";
  background-color: #f7f7f7;
  border-top: 1px solid #ee7f01;
  padding-bottom: 10.56px;
  font-size: 1.1em;
}
.main1-FAQ-dad-bottom1-1 button {
  border: none;
  font-size: 1.8em;
  font-family: "poppins-b";
  color: #ee7f01;
  font-family: "poppins-b";
  background-color: #f7f7f7;
  /* padding: 3px 0; */
  line-height: 1.6;
  cursor: pointer;
}
.main1-FAQ-dad-bottom1-2 {
  color: #777777;
  font-size: 1.05em;
  padding: 16px 36.8px 0 36.8px;
}
.main1-FAQ-dad-bottom1-2 strong {
  color: #777777;
  font-family: "poppins-b";
}
.main1-FAQ-dad-bottom1-3 {
  color: #777;
  font-size: 1.05em;
  padding: 20.8px 36.8px 36.8px 36.8px;
}
.main1-FAQ-dad-bottom1-3 strong {
  color: #777777;
  font-family: "poppins-b";
}

.main1-FAQ-dad-bottom2 {
  padding: 10.56px 40.48px 10.56px 0;
  border-top: 1px solid #ddd;
  /* color: #adadad; */
  font-size: 1.2em;
}
.main1-FAQ-dad-bottom2:hover {
  padding: 10.56px 40.48px 10.56px 0;
  border-top: 1px solid #ddd;
  color: #ee7f01;
  font-size: 1.2em;
}
.main1-FAQ-dad-bottom2 button {
  border: none;
  font-size: 1.5em;
  font-family: "poppins-b";
  color: #adadad;
  background-color: #ffffff;
  cursor: pointer;
}
.main1-FAQ-dad-bottom2 button:hover {
  border: none;
  font-size: 1.5em;
  font-family: "poppins-b";
  color: #ee7f01;
  background-color: #ffffff;
  cursor: pointer;
}
.main1-uber-dad {
  margin: 30px;
  padding-bottom: 90px;
}
.main1-uber-dad-down {
  padding: 24px 0;
  justify-content: space-between;
}
.main1-uber-dad-down-left {
  margin: 0 15px;
  width: 50%;
  text-align: justify;
}
.main1-uber-dad-down-right {
  margin: 0 15px;
  width: 50%;
}
.main1-uber-dad-down-left1 {
  font-size: 1.79em;
  line-height: 1.3;
  font-family: "poppins-b";
  color: #555;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5em;
  text-rendering: optimizeSpeed;
  text-overflow: ellipsis;
  margin-bottom: 13.6px;
}
.main1-uber-dad-down-left1-spn1 {
  color: #ee7f01;
  word-spacing: -6;
}
.main1-uber-dad-down-left1-spn2 {
  color: #777;
  word-spacing: 3;
}
.main1-uber-dad-down-left2 {
  height: 3px;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1em 0 1em;
  width: 100%;
  max-width: 270px;
}
.main1-uber-dad-down-left3 {
  font-size: 1.3em;
  color: #777;
  line-height: 1.6;
  word-spacing: 8px;
}
.main1-uber-dad-down-left4 {
  font-size: 1em;
  color: #777;
  line-height: 1.6;
  margin-top: 28.4px;
  word-spacing: 5.5px;
}

.main1-uber-dad-down-right1-1 {
  background-color: #ffffff;
  height: 193.2px;
  width: 48%;
  text-align: center;
  padding: 0px;
  margin: 0;
  border: 1px solid rgba(230, 224, 224, 0.85);
  border-top: 0.5px solid rgba(230, 224, 224, 0.15);
  border-bottom: 2px solid rgba(212, 205, 205, 0.95);
}
.main1-uber-dad-down-right1-1:hover {
  background-color: #ffffff;
  height: 193.2px;
  width: 48%;
  text-align: center;
  padding: 0px;
  margin: 0;
  border: 1px solid rgba(230, 224, 224, 0.85);
  border-top: 0.5px solid rgba(230, 224, 224, 0.15);
  border-bottom: 2px solid rgba(212, 205, 205, 0.95);
  box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: 0.5s;
}
.main1-uber-map {
  width: 100%;
  height: 480px;
  border: 0px;
  pointer-events: none;
  margin-top: 10px;
}
.main1-kontakt {
  /* height: 1437px;
    background-color: rgb(253, 200, 227); */
}
.main1-kontakt-map {
  width: 100%;
  height: 480px;
  border: 0px;
  pointer-events: none;
}
.main1-kontakt-dad2-box {
  margin-top: 5px;
}
.main1-dashB {
  /* height: 87.600px; */
  /* background-color: rgb(253, 200, 227);  */
  background-color: #f7f7f7;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.main1-dashB-dad {
  padding: 15px;
  letter-spacing: 0.05em;
}
.main1-dashB-dad1 {
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.7em;
  color: #555;
  width: 100%;
  font-family: "poppins-b";
}
.main1-dashB-dad2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.8em;
  color: #777;
  width: 100%;
  padding-top: 5px;
}
.main2-dashB {
  /* height: 437px; */
  /* background-color: pink; */
}
.main2-dashB-dad {
  /* background-color: plum; */
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
}
.main2-dashB-left {
  /* background-color: powderblue; */
  padding: 0 0 30px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  width: 30%;
}
.main2-dashB-left-top {
  /* background-color: skyblue; */
  /* height: 70px; */
}
.main2-dashB-left-top img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}
.main2-dashB-left-top2 {
  margin: auto 15px;
  /* background-color: rgb(243, 242, 247); */
  font-size: 1em;
  color: #777;
}
.main2-dashB-left-top2 span {
  color: rgb(119, 119, 119, 0.5);
}
.main2-dashB-left-bottom-ul {
  margin-top: 15px;
}
.main2-dashB-left-bottom-ul li {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  z-index: 1;
  letter-spacing: 0.02em;
  font-size: 0.8em;
  font-weight: 700;
}
.main2-dashB-left-bottom-ul li:hover {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  z-index: 1;
  letter-spacing: 0.02em;
  border-right: 3.5px solid #ee7f01;
  transition: all 0.3s;
  font-size: 0.8em;
  font-weight: 700;
}
.main2-dashB-left-bottom-a {
  cursor: pointer;
  color: #777;
  width: 100%;
}
.main2-dashB-left-bottom-a:hover {
  color: rgba(17, 17, 17, 0.85);
}
.main2-dashB-right {
  margin: 0 15px 30px 30px;
  color: #777;
  width: 70%;
}
.main2-dashB-right-txt2 {
  margin-top: 20.8px;
}
.main2-dashB-right-bottom {
  margin-top: 20.8px;
  /* background-color: rgb(226, 181, 196); */
  /* height: 150.550px; */
}
.main2-dashB-right-bottom-ul {
  padding-bottom: 27.04px;
  border-bottom: 2px #dedede solid;
}
.main2-dashB-right-bottom-ul li {
  margin-bottom: 12.48px;
  width: 32%;
  /* background-color: pink; */
}
.main2-dashB-right-bottom-a {
  /* background-color: rgb(247, 141, 247); */
  /* width: 297.49px; */
  width: 100%;
  line-height: 1;
  font-size: 1.3em;
  display: block;
  border-radius: 5px;
  padding: 20px 0;
  border: 1px solid #ddd;
  text-align: center;
}
.main2-dashB-right-bottom-a:hover {
  background-color: #555555;
  color: #ffffff;
  line-height: 1;
  font-size: 1.3em;
  /* width: 33.333%; */
  /* width: 297.49px; */
  display: block;
  border-radius: 5px;
  padding: 20px 0;
  border: 1px solid #ddd;
  text-align: center;
}
.main2-add-right {
  margin: 0 15px 30px 30px;
  color: #777;
  width: 70%;
}

.min-height-60{
  min-height: 60vh;
}
.main2-add-right-txt2 {
  margin-top: 20.8px;
  /* background-color: lime; */
}
.main2-add-right-txt2-box {
  width: 198.8px;
  /* height: 170px; */
  /* background-color: lightsteelblue; */
  padding-right: 30px;
}
.main2-add-right-txt2-box1-txt {
  color: #555;
  margin-bottom: 10px;
  width: 100%;
  margin-top: 0;
  font-size: 1.25em;
  font-weight: 700;
  text-rendering: optimizeSpeed;
  font-family: "poppins-b";
}
.main2-zah-txt2 {
  margin-top: 20.8px;
}
.main2-zah-a {
  background-color: #ee7f01;
  color: #fff;
  width: 100%;
  padding: 8 18.624px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  font-weight: bolder;
  text-align: center;
  margin-right: 1em;
  line-height: 2.4em;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: "poppins-b";
}
.main2-zah-a:hover {
  background-color: #c3690c;
  color: #fff;
  width: 100%;
  padding: 8px 18.624px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  font-weight: bolder;
  text-align: center;
  margin-right: 1em;
  line-height: 2.4em;
  max-width: 100%;
  border: 1px solid transparent;
  transition: 0.2s;
}
.main2-kart-top {
  padding-top: 10px;
}
.main2-kart-top-left input {
  margin: 0 10px 0 5px;
  float: none;
}
.main2-kart-top-left {
  color: #222;
  font-size: 0.9em;
  font-family: "poppins-b";
}
.main2-kart-top-right img {
  width: 43px;
  height: 26px;
  margin: 0 2px;
}
.main2-kart-middle {
  margin-bottom: 36px;
  padding: 10px 12px;
  color: #dcdfe6;
}
.main2-kart-middle1 i {
  font-size: 21px;
}
.main2-kart-middle1 {
  width: 50%;
  color: #dcdfe6;
}
.main2-kart-middle2 {
  width: 50%;
  direction: rtl;
  padding-top: 8px;
}
.main2-kart-middle input {
  border: none;
  font-size: 18px;
}
.main2-kart-middle input::placeholder {
  color: #b3bfca;
}
.main2-kontoD-button2 {
  background-color: #006ad0;
  color: #fff;
  padding: 0 18.624px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  font-weight: bolder;
  text-align: center;
  line-height: 2.4em;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: "poppins-b";
}
.main2-kontoD-button2:hover {
  background-color: #1658aa;
  color: #fff;
  padding: 0 18.624px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  font-weight: bolder;
  text-align: center;
  line-height: 2.4em;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: "poppins-b";
}
.main2-kart-a {
  /* background-color:#006ad0; */
  color: #fff;
  /* width: 100%;
    padding: 8 18.624px;
    font-size: .97em;
    letter-spacing: .03em;
    font-weight: bolder;
    text-align: center;
    margin-right: 1em;
    line-height: 2.4em;
    max-width: 100%;
    border: 1px solid transparent;
    font-family: "poppins-b"; */
}
.main2-kart-a:hover {
  /* background-color:#1658aa; */
  color: #fff;
  /* width: 100%;
    padding: 8 18.624px;
    font-size: .97em;
    letter-spacing: .03em;
    font-weight: bolder;
    text-align: center;
    margin-right: 1em;
    line-height: 2.4em;
    max-width: 100%;
    border: 1px solid transparent;
    font-family: "poppins-b"; */
}
.main2-add-right1 {
  justify-content: space-between;
}
.main2-kontoD-1 {
  color: #222;
  font-size: 0.9em;
  font-family: "poppins-b";
  width: 48%;
}
.main2-kontoD-1 input {
  margin-top: 5.76px;
  width: 100%;
  height: 36.9px;
  padding: 0 15.52px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.507em;
  font-size: 1.1em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  color: #333;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  font-family: "poppins";
}
.main2-kontoD-1 input:hover {
  box-shadow: none;
}
.main2-kontoD-2 {
  color: #777;
  font-size: 1em;
  margin-top: 15.52px;
}
.main2-kontoD-3 {
  color: #777;
  font-size: 1em;
  margin-top: 15.52px;
}
.main2-kontoD-3-legend {
  width: 100%;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin: 30px 0 15px;
  font-family: "poppins-b";
  text-transform: uppercase;
  display: block;
}
.main2-kontoD-button {
  background-color: #ee7f01;
  color: #fff;
  /* width: 250px; */
  padding: 0 18.624px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  font-weight: bolder;
  text-align: center;
  line-height: 2.4em;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: "poppins-b";
  cursor: pointer;
}
.main2-kontoD-button:hover {
  background-color: #c3690c;
  color: #fff;
  padding: 0 18.624px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  font-weight: bolder;
  text-align: center;
  line-height: 2.4em;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: "poppins-b";
}
.best-table-date {
  padding-left: 7.2px;
}
.best-table-status {
  padding-left: 7.2px;
  max-width: 120px;
}

.best-table-status .rs-btn {
  padding-left: 0px !important;
}
.best-table-total {
  padding-left: 7.2px;
}
.best-table-actions {
  text-align: right;
  padding: 7.2px 0 7.2px 7.2px;
}
.best-table-actions-td {
  text-align: right;
  padding: 7.2px 0 21.168px 7.2px;
  /* margin-bottom: 13.968px; */
}
/* .best-table-actions-td a
{
    margin-bottom: 13.968px;
} */
.best-table-total-span {
  white-space: nowrap;
  color: #111;
  font-family: "poppins-b";
}
.best-table-actions-a {
  background-color: #ee7f01;
  color: #fff;
  width: 100%;
  padding: 8 16.762px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  text-align: center;
  margin: 0 13.968px 13.968px 0;
  line-height: 2.4em;
  max-width: 100%;
  font-family: "poppins-b";
  /* border: 1px solid #ee7f01;
    vertical-align: middle; */
}
.best-table-actions-a:hover {
  background-color: #c3690c;
  color: #fff;
  width: 100%;
  padding: 8 16.762px;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  text-align: center;
  margin: 0 13.968px 0 0;
  line-height: 2.4em;
  max-width: 100%;
  font-family: "poppins-b";
}
.filter {
  height: 100vh;
  background-color: rgb(119, 119, 119, 0.9);
}
.filter-dad {
  width: 260px;
  padding: 30px 20px;
  text-align: left;
  /* background-color: rgba(255,255,255,.95); */
  background-color: rgba(255, 255, 255, 0.95);
  /* height: 500px; */
  overflow-y: scroll;
}
.filter-top {
  justify-content: left;
}
.filter-top input {
  width: 172.3px;
  height: 38.9px;
  padding: 0 11.64px;
  font-size: 0.97em;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%), inset 0 1px 2px rgb(0 0 0 / 10%);
}
.filter-top input:hover {
  box-shadow: none;
}
.filter-top button {
  width: 47.7px;
  height: 38.8px;
  background-color: #006ad0;
  border: none;
}
.filter-top button:hover {
  width: 47.7px;
  height: 38.8px;
  border: none;
  background-color: #1658aa;
}
.filter-top-i {
  color: #fff;
  font-size: 28px;
  font-family: "poppins-b";
}
.filter-middle {
  margin-top: 20.8px;
}
.filter-middle1 {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.05;
  letter-spacing: 0.05em;
}
.filter-middle2 {
  margin-top: 0.66em;
  height: 3px;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1em 0 1em;
  width: 100%;
  max-width: 30px;
}
.filter-middle3-ul li {
  padding: 6px 0;
  border-bottom: 1px solid #ececec;
  font-size: 1em;
}
.count {
  opacity: 0.5;
  font-size: 0.7em;
  line-height: 3em;
}
#filter-middle3 {
  border-bottom: none;
}
.filter-bottom {
  margin-top: 24px;
  margin-bottom: 50px;
}
.menu {
  height: 800px;
  border-color: rgba(0, 0, 0, 0.09);
}
.menu-dad {
  /* background-color: #fafdff; */
  /* margin-top: 50px; */
  width: 46%;
  overflow: hidden;
}
.menu-ul {
  text-align: center;
}
.menu-ul2 li {
  /* margin-bottom: 1.3em; */
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-align: center;
  color: #666;
}
.menu-ul2 li:hover {
  /* margin-bottom: 1.3em; */
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: rgba(17, 17, 17, 0.85);
  transition: 2s;
  cursor: pointer;
  text-align: center;
}
.menu-ul2 li i {
  font-size: 17px;
}
.menu-ul2 li span {
  margin-left: 5px;
}
.menu-ul2 li span:hover {
  margin-left: 5px;
}
.menu-li2 {
  padding: 13.6px 0;
}
.menu-li2-dad {
  /* background-color: blueviolet; */
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 99px;
}
.menu-li2-dad input {
  width: 200px;
  padding: 0 0.75em;
  height: 33px;
  font-size: 0.97em;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
}
.menu-li2-dad button {
  height: 33px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: none;
  vertical-align: middle;
  font: inherit;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
}
.menu-li2-i {
  color: #666;
  font-size: 20px;
  font-family: "poppins-b";
  /* padding-bottom: 20px ; */
}
.menu-li3 {
  display: flex;
  justify-content: center;
  color: rgba(102, 102, 102, 0.85);
  cursor: pointer;
}
.menu-li3:hover {
  display: flex;
  justify-content: center;
  color: rgba(17, 17, 17, 0.85);
  background-color: rgba(0, 0, 0, 0.05);
}
.menu-li3 a {
  padding: 15px 30px;
  width: 100%;
  font-size: 1.5em;
  color: rgba(102, 102, 102, 0.85);
  font-weight: 600;
  letter-spacing: 0.02em;
}
.menu-li3 a:hover {
  padding: 15px 30px;
  font-size: 1.5em;
  color: rgba(17, 17, 17, 0.85);
  font-weight: 600;
  letter-spacing: 0.02em;
}
.menu-li3-button {
  text-align: right;
  line-height: 1;
  border: none;
  color: currentColor;
  opacity: 0.6;
  right: 0;
  top: 26%;
  background: none;
  cursor: pointer;
}
.menu-li3-button:hover {
  text-align: right;
  line-height: 1;
  border: none;
  opacity: 0.6;
  right: 0;
  top: 26%;
  background: none;
}
.menu-li3-i {
  font-size: 30px;
  background-color: none !important;
  vertical-align: middle;
}
.menu-li4 {
  margin-top: 7px;
  font-size: 1.2em;
}
.head1-dad-down-right-inpt-1 {
  display: flex;
}
.head1-dad-down-right-inpt-1 input {
  box-shadow: 0px 8px 40px 0px rgba(44, 201, 181, 0.1);
  /* border-radius: 20px; */
  /* border: none; */
  margin-top: 5px;
  /* padding-right: 18px; */
  margin-left: 20px;
  /* border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  width: 96%;
  border-radius: 99px;
  padding: 10px 8px;
  margin-top: 5px; */
}
.head1-dad-down-right-inpt input {
  /* box-shadow: 0px 8px 40px 0px rgba(44, 201, 181, 0.1);
  border-radius: 20px;
  border: none;
  margin-top: 5px;
  padding-right: 18px;
  margin-left: 20px; */
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  width: 96%;
  border-radius: 99px;
  padding: 10px 8px;
  margin-top: 5px;
}

.custom-file-upload input[type="file"] {
  display: none;
}
.main1-lab-bargozari-ax {
  width: 100%;
  height: 30px;
  border: 0.7px dashed #ed4c67;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-top: 27px; */
}
.custom-file-upload {
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  /* border: 1px solid black; */
  z-index: 999;
}

.rs-control-label {
  /* padding: 25px 0px 0px 0px !important; */
  width: 90px !important;
  text-align: left !important;
}

.modalInfo .rs-control-label {
  width: 120px !important;
  text-align: left !important;
}

.anfrage-form .rs-form-control-wrapper {
  width: 100%;
}
/* .rs-form-control-wrapper{
  width: 100%;
} */
.head1-dad-down-right-inpt-anzahl-form .rs-form-control-wrapper {
  width: 80% !important;
  margin-top: 5px;
  margin-bottom: 24px;
  /* margin-left: 15px; */
}
