/* .head-z{
    position: fixed;
    z-index: 1;
} */
/* .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
} */
.head-1 {
  background-color: #f7f7f7;
}
.head-1-child1-left {
  margin-top: 10px;
}
.head-1-child1-left-txt1 {
  font-size: 0.8em;
  padding-right: 15px;
  margin-bottom: 10px;
  color: #555555;
  cursor: pointer;
}
.head-1-child1-left-txt1 li {
  color: black;
}
.head-1-child1-left-txt1 li a:hover {
  color: black;
}
.head-1-child1-left-txt2 {
  font-size: 0.8em;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-left: 1px solid #777;
  color: #555555;
  cursor: pointer;
}
.head-1-child1-left-txt3 {
  font-size: 0.8em;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-left: 1px solid #777;
  color: #555555;
  cursor: pointer;
}
.head1-icn1 {
  font-size: 16px;
  color: rgba(102, 102, 102, 0.85);
  padding-right: 5px;
  cursor: pointer;
}
.head1-icn1:hover {
  font-size: 16px;
  color: black;
  padding-right: 5px;
  cursor: pointer;
}
.head-1-child1-left-txt1:hover {
  font-size: 0.8em;
  padding-right: 15px;
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
}
.head-1-child1-left-txt1 a:hover {
  color: black;
}
.head-1-child1-left-txt2:hover {
  font-size: 0.8em;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-left: 1px solid #777;
  color: black;
  cursor: pointer;
}
.head-1-child1-left-txt2 a:hover {
  color: black;
}
.head-1-child1-left-txt3:hover {
  color: black;
  font-size: 0.8em;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-left: 1px solid #777;
  cursor: pointer;
}
.head-1-child1-left-txt3 a:hover {
  color: black;
}
.head-1-child1-right-icns {
  margin-top: 7px;
  font-size: 1.2em;
}
.head-1-child1-right-icn-1 {
  padding-left: 2px;
  padding-right: 2px;
}
.head-1-child1-right-icn-2 {
  padding-left: 2px;
}
.head-1-child1-right-icn-1 i {
  border: 2px solid silver;
  padding: 0.5px 9px;
  border-radius: 5px;
  color: silver;
  cursor: pointer;
}
.head-1-child1-right-icn-2 i {
  border: 2px solid silver;
  padding: 0.5px 9px;
  border-radius: 5px;
  color: silver;
  cursor: pointer;
}
.head-1-child1-right-icn-1 i:hover {
  color: white;
  background-color: #3a589d;
  border: 2px solid #3a589d;
}
.head-1-child1-right-icn-2 i:hover {
  color: white;
  background-color: black;
  border: 2px solid black;
}
.head-2 {
  background-color: white;
  padding-bottom: 20px;
}
.head2-child1-left {
  margin-top: 18px;
}
.head2-child1-left-logo img {
  width: 255px;
  height: 54px;
  margin-right: 30px;
}
.head2-child2-left {
  width: 504px;
  height: 33px;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid #ddd;
  color: #999;
  border-radius: 99px;
  margin-top: 10px;
}
.head2-child2-left input {
  width: 480px;
  height: 33px;
  border: none;
  border-radius: 99px;
  background-color: rgba(0, 0, 0, 0);
}
.head2-child2-left i {
  font-size: 1.2em;
  margin-right: 4px;
  cursor: pointer;
  color: #777;
}
.head2-child2-left ::placeholder {
  padding: 0 0.75em;
  font-size: 0.97em;
  letter-spacing: 0.02em;
}
.head2-child1-right {
  margin-top: 30px;
  cursor: pointer;
}
.head2-child1-right div:hover {
  color: black;
}
.head2-child1-right div a:hover {
  color: black;
}
.head2-child1-right-txt {
  /* margin-right: 15px; */
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: rgba(102, 102, 102, 0.85);
  position: relative;
}
.head2-child1-right-txt i {
  font-size: 20px;
  color: rgba(102, 102, 102, 0.85);
}
.head2-child1-right-txt i:hover {
  color: black;
}
.head2-icn1-res i:hover {
  color: black;
}
.head2-child1-right-bord {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 30px;
}
.head-3 {
  background-color: #f7f7f7;
  /* padding-bottom: 10px; */
  height: 46px;
}
.head3-dad {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.head3-child1 {
  margin-top: 10px;
}
.head3-child1 li {
  margin: 0 13px;
  font-weight: 600;
  font-size: 0.9em;
  color: rgba(102, 102, 102, 0.85);
  cursor: pointer;
}
.head3-child1 li a {
  color: rgba(102, 102, 102, 0.85);
  letter-spacing: 0.02em;
}
.head3-child1 li:hover {
  margin: 0 13px;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  color: #ee7f01;
  border-bottom: 3px solid #ee7f01;
  padding-bottom: 4px;
}
.head3-child1 li a:hover {
  color: #ee7f01;
}
#head3-li1 {
  margin: 0 13px 0 0;
}
